import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TermineMargin from "../components/termine-margin"
import { graphql, Link } from "gatsby"
import InfoPost from "../components/infopost"
import InfoPostOverlay from "../components/infopostOverlay"
import glanzlichterPic from "../images/cont_3_teaser_btn_2_over.png"
import projektePic from "../images/cont_3_teaser_btn_3_over.png"
import {StaticImage} from "gatsby-plugin-image"

export const query = graphql`
  {
    allSanityInfoPost(sort: {fields: [releaseDate], order: DESC}) {
      nodes {
        title
        _rawText
        releaseDate(locale: "de", formatString: "DD.MM.YYYY")
      }
    }
    allSanitySiteSettings {
      nodes {
        showInfoOverlay
        motto
      }
    }
  }
`

const IndexPage = ({data}) => {
  const showInfoOverlay = data.allSanitySiteSettings.nodes[0].showInfoOverlay && data.allSanityInfoPost.length > 0;

  let infoPostOverlay;
  if (showInfoOverlay) {
    infoPostOverlay = <InfoPostOverlay title={data.allSanityInfoPost.nodes[0].title}
                     releaseDate={data.allSanityInfoPost.nodes[0].releaseDate}
                     portableText={data.allSanityInfoPost.nodes[0]._rawText}/>
  } else {
    infoPostOverlay = ''
  }


  return (
  <Layout>
    <SEO title="Home"/>

    {infoPostOverlay}

    <section id="content_1">
      <h1 className="headline">{data.allSanitySiteSettings.nodes[0].motto}</h1>
      <h2 className="subhead">HERZLICH WILLKOMMEN IN DER GRUNDSCHULE L&Ouml;HNE-BAHNHOF</h2>

      <StaticImage src={"../images/Zug_Eingangshalle_HP.jpg"} alt={"Bild mit einem Zug"} width={900} style={{border: "10px solid #fff", boxShadow: "0 0 3px 0 #5d5d5d", marginTop: "40px"}}/>
      
    </section>

    <section id="content_2">
      <TermineMargin/>

      <article id="con_2_rechts">

        <div className="schulschliessung">
          {data.allSanityInfoPost.nodes.map((item) =>
              <InfoPost title={item.title} releaseDate={item.releaseDate} portableText={item._rawText} />
          )}

        </div>
      </article>
    </section>


    <section id="content_3">

      <article id="cont_50">
        <div id="cont_3_teaser_btn_2">
          <Link to="wirueberuns/glanzlichter">
            <img src={glanzlichterPic} alt="" width="180" height="179" id="teaser_btn_2"/>
          </Link>
        </div>
        <div id="cont_3_teaser_2">
          <h3 className="small_sub">
            <span style={{ color: "#007C34" }}>Unsere<br/>Glanzlichter</span>
          </h3>
          <p className="text">
            <Link className="mehr_gruen" to="wirueberuns/glanzlichter">
              <span style={{ textAlign: "center" }}>...mehr erfahren &#9658;</span>
            </Link>
          </p>
        </div>
      </article>
      <article id="cont_50">
        <div id="cont_3_teaser_btn_3">
          <Link to="wirueberuns/bildergalerien">
            <img src={projektePic} alt="" width="180" height="179" id="teaser_btn_3"/>
          </Link>
        </div>
        <div id="cont_3_teaser_1">
          <h3 className="small_sub">
            <span style={{ color: "#007C34" }}>Bildergalerien</span>
          </h3>
          <p className="text">
            <Link to="wirueberuns/bildergalerien" className="mehr_gruen">
              <span style={{ textAlign: "center" }}>...mehr erfahren &#9658;</span>
            </Link>
          </p>
        </div>
      </article>


    </section>
  </Layout>
  )
}

export default IndexPage
