import React from "react"
import PortableText from "@sanity/block-content-to-react"
import * as styles from "./infopost.module.css"
import SanityImage from "gatsby-plugin-sanity-image"

const serializers = {
  marks: {
    link: ({ mark, children }) => {
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener noreferer">{children}</a>
        : <a href={href}>{children}</a>
    },
    internalLink: ({ mark, children }) => {
      const { internalReference } = mark
      return <pre>{JSON.stringify(internalReference, null, 2)}</pre>
    },
  },
  types: {
    customImage: ({ node }) => {
      console.log(node.asset);
      return <SanityImage asset={node.asset} style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto'}} />
    },
  },
}

export default ({ title, releaseDate, portableText }) => (
  <div className={styles.infopost}>
    <h4 className={styles["infopost-title"]}>{title}</h4>
    <h5>Stand: {releaseDate}</h5>
    <PortableText blocks={portableText} serializers={serializers}/>
    <hr/>
  </div>
)
