import React from "react"
import PortableText from "@sanity/block-content-to-react"
import * as styles from "./infopostOverlay.module.css"

export default ({ title, releaseDate, portableText }) => {
  const closeFunction = () => {
    var elementById = document.getElementById("overlay")
    if (elementById) {
      elementById.style.display = "none"
    }
  }

  return (
    <div id="overlay" className="overlay">
      <div id="overlay-content" className="overlay-content">
        <h1>{title}</h1>
        <h2>Stand: {releaseDate}</h2>
        <PortableText blocks={portableText}/>

        <button className="closeButton" onClick={closeFunction}>Schließen</button>
      </div>
    </div>
  )
}
